<template>
  <div
    class="w-full mx-auto flex justify-center items-start"
    :class="[
      isPageOpenedOnModal?
        'lg:w-page-container h-min max-h-100dvh pb-3 lg:p-6'
        :'pt-16 pb-3 lg:w-page-width lg:mb-6 lg:min-h-screenMinusHeader',
      {'pt-0 pb-0 h-min max-h-100dvh':isChatOpenOnMobile()}
    ]"
  >
    <div class="w-full flex flex-col items-center"
      :class="{'lg:my-auto ':centerVertically}"
    >
      <slot/>
    </div>
  </div>
</template>

<script>
import {isChatOpenOnMobile, } from "@/common/helpers/utils";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  name: "PageContainer",
	methods: {isChatOpenOnMobile, checkIsMobileDevice},
  props: {
    centerVertically: {
      type: Boolean,
      required: false
    },
    isPageOpenedOnModal:false,
    isPageOpenedOnMobile:false,
  }
}
</script>

<style>
.h-100dvh {
	//height: 100dvh;
	//min-height: 100dvh;
	//min-height: -webkit-fill-available;
}
</style>
