<template>
  <div class="w-full text-center flex flex-col justify-center items-center h-screen">
    <Slogan
      text1="LOGO:Segment1"
      text2="LOGO:Segment2"
      :text2-highlighted="true"
      text3="LOGO:Segment3"
      desktop-size="7xl"
      :mobile-divider=9
    />
    <div class="pt-8 lg:pt-16 flex flex-col gap-8 lg:gap-16 items-center gap-5 justify-center">
      <span class="text-lg lg:text-2xl">
        {{ trans('MESSAGE:404') }}
      </span>
      <Button
        variant="buttonRedLarge"
        class="uppercase"
        @clicked="$router.back()"
      >
        <span>
          {{ trans('BUTTON:BackToPreviousPage') }}
        </span>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import Slogan from "@/views/content/components/Homepage/Slogan";
export default {
  name: "Error404",
  components: {BaseIcon, Button, Slogan},
}
</script>